import { observable, makeObservable, computed } from "mobx";
import axios from 'axios'

import backend from "./backend";
import {User, DataStats} from "./api_types"
import config from './config';
import { createBrowserHistory } from 'history';
import {randomString} from './utils'
import {versions,VersionDef} from './ver'
import logger from "logger";
const print = console.log


class Manager {
    user  : User | null = null
    stats : DataStats|undefined = undefined
    history = createBrowserHistory({ basename: '/' })
    ver = "v1"

    constructor() {

        makeObservable(this, {
            isLoggedIn: computed,
            user: observable,
            stats : observable,
        });
    }


    async init() {
        await this.checkLogin()  
    }

    setUser(user : User){
        this.ver = user.ver
        this.user = user
        logger.setContext("user_email", user.email)
        logger.event("login", {uid: user.uid, ver: user.ver})
    }

    onLogin(user : User, path?:string) {
        this.setUser(user)
        //this.ver = 
        localStorage.setItem('token', user.token);
        if (path && ! path.startsWith("/login")) {
            this.goto(path)
        } else {
            this.goto("/ann")
        }
    }
    get isLoggedIn() {
        return this.user !== null 
    }
    get isAdmin() {
        return this.isLoggedIn  && this.user?.is_admin
    }
    get isKemtaiAdmin() {
        return this.isAdmin && this.user && this.user.uid <= 2
    }
    get isSuperPower() {
        return this.isAdmin && this.user && this.user.uid===1
    }

    version(ver?:string) : VersionDef {
        return versions[ver || this.ver]
    }

    async checkLogin() {
        if (this.isLoggedIn) {
            return true
        }
        const token = localStorage.getItem('token');
        if (token) {
            const user = await backend.checkToken(token)
            if (user && user.status === "ok") {
                this.setUser(user)
                return true
            }
        }
        return false
    }
    logout = () => {
        logger.event("logout")
        logger.clearContext('user_email')
        this.user = null
        localStorage.removeItem('token');
        this.goto("/login")
    }

    goto(path:string){  
        logger.event("goto",{path})

        console.log("goto",path)
        this.history.push(path)
    }
    qcmode(q:string) {
        this.goto(`/ann?${q}`)
    }

    async checkVersion() {
        const res = await axios.get("/meta.json?"+randomString(10))
        //print("checkVersion",config.version, res.data.version)

        if (res.data.version && config.version !== res.data.version) {
            window.location.reload()
        }
    }

}

  
const manager = new Manager();
manager.init()
export default manager;