//import assert from 'assert';
import { observable, makeObservable } from "mobx";

import manager from '../../Manager';
import { DataDesc } from '../../api_types';
import backend from "../../backend";
import {assert, examMetrics, sleep} from '../../utils'
import { Keypoints } from '../../skeleton-lib/keypoints';
import logger from "logger";

const print = console.log

enum AnnotatorMode {
    annotator, 
    qc
}


export class Controller {
    mode : AnnotatorMode = AnnotatorMode.annotator
    todo    : DataDesc[] = []
    current : number = 0

    ExamDialog = false;

    constructor(query? : string) {
        if (manager.user?.is_admin) {
           if (query) {
               this.qcmode(query)
           }
        } 
        makeObservable(this, {
            ExamDialog : observable,
        });

    }

    setAid(aid:number) {    
        this.todo[this.current] = {aid}
    }
    

    qcmode = async (query:string) => {
        this.mode = AnnotatorMode.qc
        this.current = -1
        const res = await backend.getQCData2(query) 
        this.todo = res
        this.current = 0
    }

    back() {
        if (this.current>0) {
            this.current --
        }
    }

    next(step:number=0) {
        console.log("next",step)
        manager.checkVersion()

        if (step > 0 && this.mode === AnnotatorMode.qc) {
            console.log("strong next >>> ",this.current, this.todo.length)
            this.current = Math.min(this.todo.length-1,this.current + step)
            console.log("strong next <<< ",this.current)

        } else {
            this.current ++;
        }
    }
 
    async save(keypoints:Keypoints, flaggedout:boolean=false) {
        const aid = this.todo[this.current].aid!
        const isExam = this.todo[this.current].hidden_exam
        logger.event("save",{aid,isExam})

        if (isExam) {
            await backend.saveHiddenExam(keypoints, aid)
        } else {
            const newAid = await backend.saveData(keypoints, aid, flaggedout)

            this.todo[this.current] = await backend.getDataAid(newAid)
        }
    }


    async saveExam() {
        const aid = this.todo[this.current].aid!
        await backend.saveExam(aid)
    }


    async saveExamAttempt() {
        const data      = this.todo[this.current]
        const keypoints = data.keypoints!
        const exam = data.exam!
        const metrics = examMetrics(keypoints,exam)
        const aid = data.aid!
        await backend.saveExamAttempt(keypoints, aid,metrics)
    }

    async delete(aid:number) {
        if (manager.user?.is_admin && manager.user?.uid===1) {
            const resp =  await backend.delete(aid)
            console.log("delete got resp:",resp)
            if (resp.aid) {
                this.todo[this.current] = await backend.getDataAid(resp.aid)
            }
        }
    }

    async getData() {  
        //print("getData mode=",this.mode, this.current, this.todo.length)
        while (this.current<0) {
            await sleep(1)
        }
        if (this.mode === AnnotatorMode.annotator) {
            if (this.current >= this.todo.length) {
                const todo = await backend.getData()
                //print("hidden_exam:",todo[0].hidden_exam)
                this.todo.push(todo[0])
                this.current = this.todo.length - 1
            }
        } else {  

            if (this.current >= this.todo.length) {
                //print("***** End of qc list ",this.current,this.todo.length)
                manager.goto("/admin")
                return
            }  
            
            if (!this.todo[this.current].keypoints) {
                assert(this.todo[this.current].aid || this.todo[this.current].hash)
                this.todo[this.current] = await backend.getDataSample(this.todo[this.current])
            }
        }
        return this.todo[this.current] 
    }
}


